import React from 'react';
import * as d3 from 'd3';
import './Simulation.css';
import 'array-flat-polyfill';

var randomNormal = require('random-normal');

class Parameters extends React.Component {
    constructor(props) {
        super(props);

        this.handleStartBalanceChange = this.handleStartBalanceChange.bind(this);
        this.handleStartAgeChange = this.handleStartAgeChange.bind(this)
        this.handleRetirementAgeChange = this.handleRetirementAgeChange.bind(this);
        this.handleAnnualSavingsChange = this.handleAnnualSavingsChange.bind(this);
        this.handleNumSimulationsChange = this.handleNumSimulationsChange.bind(this);
    }


    handleStartBalanceChange(e) {
        this.props.handleStartBalanceChange(parseInt(e.target.value));
    }

    handleStartAgeChange(e) {
        this.props.handleStartAgeChange(parseInt(e.target.value));
    }

    handleRetirementAgeChange(e) {
        this.props.handleRetirementAgeChange(parseInt(e.target.value));
    }

    handleAnnualSavingsChange(e) {
        this.props.handleAnnualSavingsChange(parseInt(e.target.value));
    }

    handleNumSimulationsChange(e) {
        this.props.handleNumSimulationsChange(parseInt(e.target.value));
    }

    render() {
        return (
            <div>
                <h2>Simulation Parameters</h2>
                <form id="simulation_params">
                    <label>Current Savings:<input type="number" value={this.props.startBalance} onChange={this.handleStartBalanceChange} /></label>
                    <label>Current Age:<input type="number" value={this.props.startAge} onChange={this.handleStartAgeChange} /></label>
                    <label>Retirement Age:<input type="number" value={this.props.retirementAge} onChange={this.handleRetirementAgeChange} /></label>
                    <label>Annual Savings:<input type="number" value={this.props.annualSavings} onChange={this.handleAnnualSavingsChange} /></label>
                    <label>Simulation Runs:<input type="number" value={this.props.numSimulations} onChange={this.handleNumSimulationsChange} /></label>
                </form>
            </div>
        );
    }
}

class Simulation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startBalance: 1000,
            startAge: 42,
            retirementAge: 65,
            annualSavings: 6000,
            numSimulations: 5000,
        };
        if (window.location.hash) {
            this.state = JSON.parse(window.atob(window.location.hash.substr(1)));
        }

        this.handleStartBalanceChange = this.handleStartBalanceChange.bind(this);
        this.handleStartAgeChange = this.handleStartAgeChange.bind(this);
        this.handleRetirementAgeChange = this.handleRetirementAgeChange.bind(this);
        this.handleAnnualSavingsChange = this.handleAnnualSavingsChange.bind(this);
        this.handleNumSimulationsChange = this.handleNumSimulationsChange.bind(this);
    }

    handleStartBalanceChange(startBalance) {
        this.setState({ startBalance: startBalance });
    }

    handleStartAgeChange(startAge) {
        this.setState({ startAge: startAge });
    }

    handleRetirementAgeChange(retirementAge) {
        this.setState({ retirementAge: retirementAge });
    }

    handleAnnualSavingsChange(annualSavings) {
        this.setState({ annualSavings: annualSavings });
    }

    handleNumSimulationsChange(numSimulations) {
        this.setState({ numSimulations: numSimulations });
    }

    balancesByYear() {
        var years = [];
        var start = performance.now();
        for (var i = 0; i < this.state.numSimulations; i++) {
            years.push(this.balanceByYear());
        }
        var end = performance.now();
        console.log("took " + (end - start) + "ms to execute " + this.state.numSimulations + " simulations");
        years.sort((a, b) => a[a.length-1].balance-b[b.length-1].balance);
        return years;
    }

    balanceByYear() {
        var portfolio = {
            "US_STOCK": 0.54,
            "INTL_STOCK": 0.36,
            "US_BOND": 0.025,
            "INTL_BOND": 0.075
        };
        var meanReturn = {
            "US_STOCK": 0.07,
            "INTL_STOCK": 0.08,
            "US_BOND": 0.045,
            "INTL_BOND": 0.05
        };
        var stdDev = {
            "US_STOCK": 0.20,
            "INTL_STOCK": 0.21,
            "US_BOND": 0.08,
            "INTL_BOND": 0.09
        }
        var balance = this.state.startBalance;
        var balances = [
            {age: this.state.startAge, balance: balance},
        ];
        for (var age = this.state.startAge+1; age <= this.state.retirementAge; age++) {
            // Glide path corresponding to Vanguard TargetRetirement funds.
            var yearsToRetirement = this.state.retirementAge - age;
            if (yearsToRetirement < 25) {
                var stockRatio = 0.5 + yearsToRetirement*0.016;
                portfolio["US_STOCK"] = stockRatio*0.6;
                portfolio["INTL_STOCK"] = stockRatio*0.4;
                portfolio["US_BOND"] = (1-stockRatio)*0.25;
                portfolio["INTL_BOND"] = (1-stockRatio)*0.75;
            }
            // new savings go in before annual returns
            balance += this.state.annualSavings;
            var newBalance = 0.0;
            for (const asset in portfolio) {
                var assetBalance = balance*portfolio[asset];
                var assetReturn = Math.max(-1, randomNormal({mean: meanReturn[asset], dev: stdDev[asset]}));
                newBalance += assetBalance*(1 + assetReturn);
            }
            balances.push({age: age, balance: newBalance});
            balance = newBalance;
        }
        return balances;
    }

    render() {
        window.location.hash = '#' + window.btoa(JSON.stringify(this.state));
        
        return (
            <div className="simulation">
                <Parameters
                    startBalance={this.state.startBalance}
                    handleStartBalanceChange={this.handleStartBalanceChange}
                    startAge={this.state.startAge}
                    handleStartAgeChange={this.handleStartAgeChange}
                    retirementAge={this.state.retirementAge}
                    numSimulations={this.state.numSimulations}
                    handleRetirementAgeChange={this.handleRetirementAgeChange}
                    annualSavings={this.state.annualSavings}
                    handleAnnualSavingsChange={this.handleAnnualSavingsChange}
                    handleNumSimulationsChange={this.handleNumSimulationsChange} />
            
                <Result
                    startBalance={this.state.startBalance}
                    startAge={this.state.startAge}
                    retirementAge={this.state.retirementAge}
                    annualSavings={this.state.annualSavings}
                    balancesByYear={this.balancesByYear()} />

                <div>
                    <h2>Work-in-progress</h2>
                    <p>This retirement savings simulator is a work-in-progress. The folloing planned work remains:</p>
                    <ul>
                        <li>Separately specify annual contributions to brokerage, pre-tax &amp; Roth accounts.</li>
                        <li>Compute tax-drag in each subaccount.</li>
                        <li>Display information about each series on mouseover.</li>
                        <li>Static random number generator initialization to make simulations hermetic &amp; reproducible.</li>
                        <li>Advanced: allow custom portfolios / expected return assumptions.</li>
                        <li>Provide way of visually exploring how outcomes change if you adjust retirement age or savings rate.</li>
                        <li>More concise encoding scheme for parameters to make URL hash shorter.</li>
                    </ul>
                    <p>Please send any other feedback or feature requests to <a href="mailto:lifesim@c6e.me">lifesim@c6e.me</a>.</p>
                </div>
            </div>
        );
    }
}

class Result extends React.Component {

    render() {
        var numSimulations = this.props.balancesByYear.length;
        var numYears = this.props.balancesByYear[0].length;
        var percentile = (n) =>  Math.round(this.props.balancesByYear[Math.floor((numSimulations-1)*n/100)][numYears-1].balance);
        var p1 = percentile(1);
        var p99 = percentile(99);
        var p5 = percentile(5);
        var p95 = percentile(95);
        var p50 = percentile(50);
        return (
            <div>
                <h2>Simulation Result</h2>
                <div>Start Balance: $ {this.props.startBalance.toLocaleString()}</div>
                <div>Start Age: {this.props.startAge}</div>
                <div>Retirement Age: {this.props.retirementAge}</div>
                <div>Annual Savings: $ {this.props.annualSavings.toLocaleString()}</div>
                <div>Median Retirement Balance: $ {p50.toLocaleString()}</div>
                <Viz balancesByYear={this.props.balancesByYear} />
                <div class="legend">
                    <p>Colored lines represent the simulation runs whose value at retirement represent certain key percentiles among all simulation outcomes:</p>
                    <p><div class="box red"></div> Represents the 1st & 99th percentile secnarios. Your outcome has a 98% chance of falling between ${p1.toLocaleString()} and ${p99.toLocaleString()}.</p>
                    <p><div class="box yellow"></div> Represents the 5th & 95th percentile secnarios. Your outcome has a 90% chance of falling between ${p5.toLocaleString()} and ${p95.toLocaleString()}.</p>
                    <p><div class="box pink"></div> Represents the 50th percentile secnario. Your outcome is equally likely to fall above or below ${p50.toLocaleString()}.</p>
                </div>
                <p>The simulation assumes you invest funds in a typical "target-date" retirement fund, matching the retirement age in the input parameters. This simulation assumes a glide path from 90% stocks to 50% stocks starting 25 years prior to retirement. Stocks are 60% US, 40% ex-US. Bonds are 75% US, 25% ex-US (hedged).</p>
                <p>The following expected returns are used to independently sample each asset class' return each year:</p>
                <table>
                    <tr>
                        <th>Asset Class</th><th>Mean Return</th><th>Standard Deviation</th>
                    </tr>
                    <tr>
                        <td>US Equities</td><td>7%</td><td>20%</td>
                    </tr>
                    <tr>
                        <td>Ex-US Equities</td><td>8%</td><td>21%</td>
                    </tr>
                    <tr>
                        <td>US Bonds</td><td>4.5%</td><td>8%</td>
                    </tr>
                    <tr>
                        <td>Ex-US Bonds (USD Hedged)</td><td>5%</td><td>9%</td>
                    </tr>
                </table>
                <p>Note: independent returns are a dramatically simplifying assumption, a future version of this simulator may sample from a distribution that also considers asset classes' correlations.</p>
            </div>
        );
    }
}

class Viz extends React.Component {

    componentDidMount() {
        this.draw(this.props);
    }

    componentDidUpdate() {
        this.draw(this.props)
    }

    draw(props) {
        d3.select('.viz > *').remove();

        var start = performance.now();
        var data = this.props.balancesByYear;
        var flat = this.props.balancesByYear.flat();

        var margin = {top: 40, right: 40, bottom: 40, left: 100},
            width = 960,
            height = 500;

        var svg = d3.select('.viz').append('svg')
            .attr('height', height)
            .attr('width', width)
            .attr('id','#svg-viz')
            .attr("class", "dot chart")
            .append("g")
                .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        var x = d3.scaleLinear()
            .domain(d3.extent(flat, d => d.age))
            .range([0, width - margin.left - margin.right])
            .nice();

        var y = d3.scaleLinear()
            .domain(d3.extent(flat, d => d.balance))
            .range([height - margin.top - margin.bottom, 0])
            .nice();

        var xAxis = d3.axisBottom()
            .scale(x)
            .tickPadding(8);

        var yAxis = d3.axisLeft()
            .scale(y)
            .tickPadding(8);

        var highlights = [];
        var background = svg.append("g");
        var foreground = svg.append("g");
        for (var p = 0; p <= 100; p++) {
            var np = p;
            if (p === 0) {
                np = 0.5;
            }
            if (p === 100) {
                np = 99.95;
            }
            var i = Math.floor((data.length-1)*np/100);
            var color = "#f0f1f6";
            var stroke = 1;
            var highlight = false;
            var group = background;
            if (p === 1 || p === 99) {
                color = "#d7743b";
                highlight = true;
            }
            if (p === 5 || p === 95) {
                color = "#e6b300";
                highlight = true;
            }
            if (p === 50) {
                color = "#fec4b2";
                highlight = true;
            }
            if (highlight) {
                stroke = 2;
                highlights.push(data[i]);
                group = foreground;

            }
            group.append("path")
                .attr("stroke", color)
                .attr("stroke-width", stroke)
                .attr("fill", "none")
                .attr("d", d3.line().x(d => x(d.age)).y(d => y(d.balance))(data[i]));
        }

        foreground.selectAll(".dot")
            .data(highlights.flat())
            .enter().append("circle")
            .attr("class", "dot")
            .attr("cx", d => x(d.age))
            .attr("cy", d => y(d.balance))
            .attr("r", 3);

        svg.append("g")
            .attr("class", "x axis")
            .attr("transform", "translate(0," + y.range()[0] + ")")
            .call(xAxis);

        svg.append("g")
            .attr("class", "y axis")
            .call(yAxis);

        var end = performance.now();
        console.log("took " + (end - start) + "ms to draw " + data.length + " simulations");
    }

    render() {
        return (
            <div className="viz" />
        )
    }
}

export default Simulation;
